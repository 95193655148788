import React from "react";
// import { strings } from "../../Translations/strings";
import "./MobileMenu.scss";

const MobileMenu = ({ mobileMenuClass, controlMobileMenu }) => {
  return (
    <div className={mobileMenuClass} id="mobile-menu">
      {/* <div className="logo-container">
        <img src={logoMobile} alt="logo" />
      </div> */}
      <div className="nav-container">
        <ul>
          <li onClick={controlMobileMenu}>
            <a href="/">
              Početna
              {/* {strings.nav_about_us_label} */}
              {/* <img
                  src={require("./assets/arrow-right.svg").default}
                  alt=""
                /> */}
            </a>
          </li>
          <li onClick={controlMobileMenu}>
          <a href="#products">Proizvodi
              {/* {strings.nav_products_label} */}
              {/* <img
                  src={require("./assets/arrow-right.svg").default}
                  alt=""
                /> */}
            </a>
          </li>
          <li onClick={controlMobileMenu}>
          <a href="#about-us">O nama
              {/* <img
                  src={require("./assets/user-circle.svg").default}
                  alt="logo"
                /> */}
              {/* {strings.nav_my_profile_label} */}
            </a>
          </li>
          <li onClick={controlMobileMenu}>
          <a href="#contact">Kontakt
              {/* {strings.nav_finansing_label} */}
              {/* <img
                  src={require("./assets/arrow-right.svg").default}
                  alt=""
                /> */}
            </a>
          </li>
          
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
