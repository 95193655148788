import React from "react";
import "./Header.scss";
import background from "../assets/header-bgd.png";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navbar from "../Navbar/Navbar";

export default function Header({ isMobileMenuActive, controlMobileMenu }) {
  const mobileMenuClass = isMobileMenuActive
    ? "mobile-menu active"
    : "mobile-menu";
  const hamburgerClass = isMobileMenuActive ? "hamburger active" : "hamburger"
  const overlayClass = isMobileMenuActive ? "overlay active" : "overlay"


  return (
    <header className="header">
      <div className={overlayClass}></div>

      <Navbar
        isMobileMenuActive={isMobileMenuActive}
        controlMobileMenu={controlMobileMenu}
      />
      {/* hamburger button */}
      <div className={hamburgerClass} onClick={controlMobileMenu}>
        <a className="main-nav-toggle">
          <i>Menu</i>
        </a>
      </div>

      <div className="container-lg">
        <div className="text-container">
          <h1>Posvećeni vrhunskom kvalitetu i rezultatima</h1>
          {/* <p className="subtitle">Podnaslov</p> */}
          <p className="text">
            Naša stručnost i integritet temelj su dugoročnih odnosa i povjerenja
            koje dijelimo s našim klijentima.
          </p>
          <a href="#products" className="link-green">NAŠI PROIZVODI</a>
          {/* <button className="btn-custom btn-outline">NASI PROIZVODI</button>
          <button className="btn-custom btn-green">NASI PROIZVODI</button> */}

        </div>
      </div>

      <MobileMenu mobileMenuClass={mobileMenuClass} controlMobileMenu={controlMobileMenu}></MobileMenu>
    </header>
  );
}
