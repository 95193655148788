import React from "react";
import "./Navbar.scss";
import logo from "../assets/izobit-logo2.png";
import phoneIcon from "../assets/phone.svg"

export default function Navbar({isMobileMenuActive, controlMobileMenu}) {

  return (
    <div id="home" className="navbar-wrapper">
      <div className="navbar-info">
        <div className="container-xl contact">
          {/* <p className="working-time">Radno vrijeme: pon - pet 07:00 - 15:00</p> */}
          <p> <img src={phoneIcon} type="" alt="izobit kontakt" className="phone-icon" /> +387 35 817 536</p>
        </div>
        <div className="container-xxl navbar-container">
          <div className="container-xl">
            <div className="logo-wrapper">
              <img src={logo} alt="izobit logo" />
            </div>

            <ul className="nav-links">
              <li>
                <a href="#home">Početna</a>
              </li>
              <li>
                <a href="#products">Proizvodi</a>
              </li>
              <li>
                <a href="#about-us">O nama</a>
              </li>
              <li>
                <a href="#contact">Kontakt</a>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
    </div>
  );
}
