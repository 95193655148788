import React, { useState } from "react";
import "./Sections.scss";
import hidro from "./assets/hidro.jpg";
import hidroMobile from "./assets/hidro-mobile.jpg";
import cestogradnja from "./assets/cestogradnja.jpg";
import cestogradnjaMobile from "./assets/cestogradnja-mobile.jpg";
import crniDerivati from "./assets/crni-derivati.jpg";
import crniDerivatiMobile from "./assets/crni-derivati-mobile.jpg";
import industrijskeHemikalije from "./assets/industrijske-hemikalije.jpg";
import prevozTereta from "./assets/prevoz-tereta.jpg";
import uslugePoMjeri from "./assets/usluge-po-mjeri.jpg";
import aboutUs from "./assets/about-us-photo.png";
import phone from "./assets/phone.png";
import mail from "./assets/mail.png";

// partners
import hifaPetrol from "./assets/hifa-petrol.png";
import halko from "./assets/halko.png";
import topDom from "./assets/top-dom.png";
import hodzex from "./assets/hodzex.png";
import jataGroup from "./assets/jata-group.png";
import hifaOil from "./assets/hifa-oil.png";
import roading from "./assets/roading.png";
import pennyPlus from "./assets/penny-plus.png";
import compact from "./assets/compact.png";
import trigma from "./assets/trigma.png";
import saga from "./assets/saga-energy.png";
import eolPetrol from "./assets/ld.png";
import { useEffect } from "react";

export default function Sections() {
  const [width, setWidth] = useState(window.innerWidth);
  const [productOneHovered, setProductOneHovered] = useState(false);
  const [productTwoHovered, setProductTwoHovered] = useState(false);
  const [productThreeHovered, setProductThreeHovered] = useState(false);
  const [productFourHovered, setProductFourHovered] = useState(false);
  const [productFiveHovered, setProductFiveHovered] = useState(false);
  const [productSixHovered, setProductSixHovered] = useState(false);

  const [descriptionMobileOneActive, setDescriptionMobileOneActive] =
    useState(false);
  const [descriptionMobileTwoActive, setDescriptionMobileTwoActive] =
    useState(false);
  const [descriptionMobileThreeActive, setDescriptionMobileThreeActive] =
    useState(false);
  const [descriptionMobileFourActive, setDescriptionMobileFourActive] =
    useState(false);
  const [descriptionMobileFiveActive, setDescriptionMobileFiveActive] =
    useState(false);
  const [descriptionMobileSixActive, setDescriptionMobileSixActive] =
    useState(false);

  // overlay classes
  const overlayOneClass = productOneHovered ? "overlay active" : "overlay";
  const overlayTwoClass = productTwoHovered ? "overlay active" : "overlay";
  const overlayThreeClass = productThreeHovered ? "overlay active" : "overlay";
  const overlayFourClass = productFourHovered ? "overlay active" : "overlay";
  const overlayFiveClass = productFiveHovered ? "overlay active" : "overlay";
  const overlaySixClass = productSixHovered ? "overlay active" : "overlay";

  // description mobile classes
  const textOneClass = descriptionMobileOneActive ? "text active" : "text";
  const textTwoClass = descriptionMobileTwoActive ? "text active" : "text";
  const textThreeClass = descriptionMobileThreeActive ? "text active" : "text";
  const textFourClass = descriptionMobileFourActive ? "text active" : "text";
  const textFiveClass = descriptionMobileFiveActive ? "text active" : "text";
  const textSixClass = descriptionMobileSixActive ? "text active" : "text";

  const btnOneCollapseClass = descriptionMobileOneActive
    ? "button-collapse collapsed"
    : "button-collapse";
  const btnTwoCollapseClass = descriptionMobileTwoActive
    ? "button-collapse collapsed"
    : "button-collapse";
  const btnThreeCollapseClass = descriptionMobileThreeActive
    ? "button-collapse collapsed"
    : "button-collapse";
  const btnFourCollapseClass = descriptionMobileFourActive
    ? "button-collapse collapsed"
    : "button-collapse";
  const btnFiveCollapseClass = descriptionMobileFiveActive
    ? "button-collapse collapsed"
    : "button-collapse";
  const btnSixCollapseClass = descriptionMobileSixActive
    ? "button-collapse collapsed"
    : "button-collapse";

  // toggle description text on hover
  const toggleCollapse = (e) => {
    console.log(e.target.id)
    const id = e.target.id
    switch (id) {
      case "1":
        setDescriptionMobileOneActive(!descriptionMobileOneActive);
        break;
      case "2":
        setDescriptionMobileTwoActive(!descriptionMobileTwoActive);
        break;
      case "3":
        setDescriptionMobileThreeActive(!descriptionMobileThreeActive);
        break;
      case "4":
        setDescriptionMobileFourActive(!descriptionMobileFourActive);
        break;
      case "5":
        setDescriptionMobileFiveActive(!descriptionMobileFiveActive);
        break;
      case "6":
        setDescriptionMobileSixActive(!descriptionMobileSixActive);
        break;
    }
    // setDescriptionMobileActive(!descriptionMobileOneActive);
  };

  // show overlay on hover
  const onMouseEnter = (e) => {
    const id = e.target.id;
    switch (id) {
      case "1":
        setProductOneHovered(!productOneHovered);
        console.log("one triggered");
        break;
      case "2":
        setProductTwoHovered(true);
        break;
      case "3":
        setProductThreeHovered(true);
        break;
      case "4":
        setProductFourHovered(true);
        break;
      case "5":
        setProductFiveHovered(true);
        break;
      case "6":
        setProductSixHovered(true);
        break;
    }
  };

  const onMouseEnterOne = () => {
    setProductOneHovered(!productOneHovered);
  };
  const onMouseLeaveOne = (e) => {
    setProductOneHovered(false);
  };
  const onMouseLeaveTwo = () => {
    setProductTwoHovered(false);
  };
  const onMouseLeaveThree = () => {
    setProductThreeHovered(false);
  };
  const onMouseLeaveFour = () => {
    setProductFourHovered(false);
  };
  const onMouseLeaveFive = () => {
    setProductFiveHovered(false);
  };
  const onMouseLeaveSix = () => {
    setProductSixHovered(false);
  };

  return (
    <div className="sections">
      <div className="container-xl">
        {/* section our partners */}
        <section className="our-partners">
          <h2>Naši partneri</h2>
          {/* <p className="subtitle">
            Nastojimo pružiti najbolju moguću uslugu krajnjim korisnicima.
          </p> */}
          <p className="text">
            U saradnji sa svojim mnogobrojnim domaćim i inozemnim partnerima
            nastojimo pružiti najbolju moguću uslugu krajnjim korisnicima naših
            proizvoda i usluga. Status partnera kod nas uživaju kompanije s
            kojima imamo visokorazvijenu poslovnu saradnju, a mogu se posmatrati
            kao prodajni i kao kupovni partneri.
          </p>
          {/* partners - first row */}
          <div className="row partners first">
            <div className="col-md-6 first">
              <div className="row">
                <div className="col-4">
                  <div className="partner">
                    <img src={hifaPetrol} alt="" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={halko} alt="" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={topDom} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 first">
              <div className="row">
                <div className="col-4">
                  <div className="partner">
                    <img src={hodzex} alt="" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={jataGroup} alt="" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={hifaOil} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* partners - second row */}
          <div className="row partners">
            <div className="col-md-6 first">
              <div className="row">
                <div className="col-4">
                  <div className="partner">
                    <img src={roading} alt="izobit partneri" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={pennyPlus} alt="izobit partneri" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={compact} alt="izobit partneri" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 first">
              <div className="row">
                <div className="col-4">
                  <div className="partner">
                    <img src={trigma} alt="izobit partneri" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={saga} alt="izobit partneri" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="partner">
                    <img src={eolPetrol} alt="izobit partneri" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* section production program */}
        <section id="products" className="production-program">
          <h2>Proizvodni program</h2>

          <div className="first-product-wrapper">
            <div className="card-product first">
              <div className="img-container">
                <div
                  id="1"
                  // className={overlayOneClass}
                  // onMouseLeave={onMouseLeaveOne}
                  className="overlay"
                >
                  <p>
                    Sve vrste hidroizolacionih bitumenskih traka, masa, pasti i
                    premaza. (Tehničku dokumentaciju dostavljamo na zahtjev
                    kupca.)
                  </p>
                </div>
                <img
                  id="1"
                  src={hidro}
                  alt="izobit proizvodi i usluge"
                  className="hidro"
                  // onMouseEnter={onMouseEnterOne}
                  // onMouseLeave={onMouseEnterOne}
                />
                <img
                  id="1"
                  src={hidroMobile}
                  alt="izobit proizvodi i usluge"
                  className="hidro-mobile"
                />
              </div>

              {/* 1 */}
              <div className={textOneClass}>
                <button
                  id="1"
                  className={btnOneCollapseClass}
                  onClick={toggleCollapse}
                  disabled={width > 768}
                >
                  Hidroizolacioni asortiman
                </button>

                <div className="description-container hidroizolacioni">
                  <p>
                    Sve vrste hidroizolacionih bitumenskih traka, masa, pasti i
                    premaza. (Tehničku dokumentaciju dostavljamo na zahtjev
                    kupca.)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row cestogradnja-derivati">
            <div className="col-md-6 first">
              <div className="card-product">
                <div className="img-container">
                  <div
                    id="2"
                    className={overlayTwoClass}
                    onMouseLeave={onMouseLeaveTwo}
                  >
                    <p>
                      Asortiman iz cestogradnje: bitumenske emulzije, polimerom
                      modificirane bitumene (PmB), most trake, fug mase, premaze
                      i mase za puteve. (Tehničku dokumentaciju dostavljamo na
                      zahtjev kupca.)
                    </p>
                  </div>
                  <img
                    id="2"
                    src={cestogradnja}
                    alt="izobit proizvodi i usluge"
                    className="cestogradnja"
                    onMouseEnter={onMouseEnter}
                  />
                  <img
                    id="2"
                    src={cestogradnjaMobile}
                    alt="izobit proizvodi i usluge"
                    className="cestogradnja-mobile"
                    // onMouseEnter={onMouseEnter}
                  />
                </div>

                {/* 2 */}
                <div className={textTwoClass}>
                  <button
                    id="2"
                    className={btnTwoCollapseClass}
                    onClick={toggleCollapse}
                    disabled={width > 768}
                  >
                    Asortiman iz cestogradnje
                  </button>

                  <div className="description-container cest">
                    <p>
                    Asortiman iz cestogradnje: bitumenske emulzije, polimerom
                      modificirane bitumene (PmB), most trake, fug mase, premaze
                      i mase za puteve. (Tehničku dokumentaciju dostavljamo na
                      zahtjev kupca.)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-product">
                <div className="img-container">
                  <div
                    id="3"
                    className={overlayThreeClass}
                    onMouseLeave={onMouseLeaveThree}
                  >
                    <p>Crni derivati: bitumen i mazut.</p>
                  </div>
                  <img
                    id="3"
                    src={crniDerivati}
                    alt="izobit proizvodi i usluge"
                    className="crni-derivati"
                    onMouseEnter={onMouseEnter}
                  />
                  <img
                    id="3"
                    src={crniDerivatiMobile}
                    alt="izobit proizvodi i usluge"
                    className="crni-derivati-mobile"
                    onMouseEnter={onMouseEnter}
                  />
                </div>

                {/* 3 */}
                <div className={textThreeClass}>
                  <button
                    id="3"
                    className={btnThreeCollapseClass}
                    onClick={toggleCollapse}
                    disabled={width > 768}
                  >
                    Crni derivati
                  </button>

                  <div className="description-container derivati">
                    <p>
                    Crni derivati: bitumen i mazut.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row other">
            <div className="col-md-4">
              <div className="card-product">
                <div className="img-container">
                  <div
                    id="4"
                    className={overlayFourClass}
                    onMouseLeave={onMouseLeaveFour}
                  >
                    <p>Proizvodnja i distribucija industrijskih hemikalija.</p>
                  </div>
                  <img
                    id="4"
                    src={industrijskeHemikalije}
                    alt="izobit proizvodi i usluge"
                    onMouseEnter={onMouseEnter}
                  />
                </div>

                {/* 4 */}
                <div className={textFourClass}>
                  <button
                    id="4"
                    className={btnFourCollapseClass}
                    onClick={toggleCollapse}
                    disabled={width > 768}
                  >
                    Industrijske hemikalije
                  </button>

                  <div className="description-container hemikalije">
                    <p>
                    Proizvodnja i distribucija industrijskih hemikalija.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-product">
                <div className="img-container">
                  <div
                    id="5"
                    className={overlayFiveClass}
                    onMouseLeave={onMouseLeaveFive}
                  >
                    <p>
                      Prijevoz tereta u unutarnjem i međunarodnom cestovnom
                      prometu.
                    </p>
                  </div>
                  <img
                    id="5"
                    src={prevozTereta}
                    alt="izobit proizvodi i usluge"
                    onMouseEnter={onMouseEnter}
                  />
                </div>

                {/* 5 */}
                <div className={textFiveClass}>
                  <button
                    id="5"
                    className={btnFiveCollapseClass}
                    onClick={toggleCollapse}
                    disabled={width > 768}
                  >
                    Prijevoz tereta
                  </button>

                  <div className="description-container prevoz">
                    <p>
                    Prijevoz tereta u unutarnjem i međunarodnom cestovnom
                      prometu.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-product">
                <div className="img-container">
                  <div
                    id="6"
                    className="overlay"
                    // className={overlaySixClass}
                    // onMouseLeave={onMouseLeaveSix}
                  >
                    <p>
                      Pružamo usluge po mjeri poput miješanja, blendiranje,
                      prepakiranja i specijaliziranog lokalnog skladištenja.
                    </p>
                  </div>
                  <img
                    id="6"
                    src={uslugePoMjeri}
                    alt="izobit proizvodi i usluge"
                    // onMouseEnter={onMouseEnter}
                  />
                </div>

                {/* 6 */}
                <div className={textSixClass}>
                  <button
                    id="6"
                    className={btnSixCollapseClass}
                    onClick={toggleCollapse}
                    disabled={width > 768}
                  >
                    Usluge po mjeri
                  </button>

                  <div className="description-container usluge">
                    <p>
                    Pružamo usluge po mjeri poput miješanja, blendiranje,
                      prepakiranja i specijaliziranog lokalnog skladištenja.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* section about us */}
        <div className="container-lg">
          <section id="about-us" className="about-us">
            <div className="row">
              <div className="col-md-7">
                <h2>O nama</h2>
                {/* <p className="subtitle">
                  Stručnost i integritet su temelj dugoročnih odnosa i
                  povjerenja
                </p> */}
                <p className="text">
                  Društvo sa ograničenom odgovornošću «IZOBIT» osnovano u Brčkom
                  2010. godine. Kompanija u privatnom vlasništvu koncentrisana
                  na industriju građevinarstva i cestogradnju. Proizvodimo i
                  distribuiramo hidroizolacione materijale na bazi bitumena,
                  materijale za izgradnju puteva i mnogobrojne hemijske
                  proizvode.
                </p>
                {/* <button className="btn-custom btn-green">VIŠE O NAMA</button> */}
                <a href="#contact" className="link link-green">
                  VIŠE O NAMA
                </a>
                <a href="#products" className="link link-outline">
                  NAŠI PROIZVODI
                </a>
                {/* <button className="btn-custom btn-outline">
                  NAŠI PROIZVODI
                </button> */}
              </div>
              <div className="col-md-5">
                <img src={aboutUs} alt="izobit proizvodi i usluge" />
              </div>
            </div>
          </section>

          {/* section contact us */}
          <section id="contact" className="contact-us">
            <h2>Kontaktirajte nas</h2>
            <p className="subtitle">
              <p className="subtitle">
                Za više potrebnih informacija, kontaktirajte nas putem e-maila,
                kontakt telefona ili lično u kancelariji.
              </p>
            </p>
            <div className="contact-us-content">
              <div className="contact-information">
                <p className="subtitle">Kontakt informacije</p>
                <p>Safvet bega Bašagića br. 1</p>
                <p>TC “Most” lokal 24.</p>
                <p>76100 Brčko Distrikt BiH</p>
                <p>Bosna i Hercegovina</p>
                <div className="contact phone">
                  <div>
                    <img src={phone} alt="izobit kontakt telefon" />
                  </div>
                  <p>+387 35 817 536</p>
                </div>
                <div className="contact mail">
                  <div>
                    <img src={mail} alt="izobit kontakt mail" />
                  </div>
                  <p>izobit.brcko@gmail.com</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
