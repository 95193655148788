import './App.css';
import Navbar from './Navbar/Navbar';
import Header from './Header/Header';
import Sections from './Sections/Sections';
import Footer from './Footer/Footer';
import { useState } from 'react';
import Test from './TestComponent/Test';
import Test2 from './TestComponent2/Test2';

function App() {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)

  const controlMobileMenu = () => {
    setIsMobileMenuActive(!isMobileMenuActive)
  }

  return (
    <div className="">
      <Header isMobileMenuActive={isMobileMenuActive} controlMobileMenu={controlMobileMenu}/>
      <Sections />
      <Footer />
      {/* <Test></Test> */}
      {/* <Test2></Test2> */}
    </div>
  );
}

export default App;
